import { useMemo, useState, useCallback } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { i18n } from '@lingui/core';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { enUS, nb } from 'date-fns/locale';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

import {
  formatDate,
  formatNumber,
  downloadFile,
  getDateWithoutTimezoneOffset,
} from 'services/helpers';
import { fromCompany, fromAuth } from 'store/selectors';
import { generatePDFReportApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

import usePreviewElement from './usePreviewElement';
import reportStyles from './styles';

const convertDate = (value) =>
  format(new Date(2023, 0, value, 12, 0, 0), 'do', {
    locale: i18n.locale === 'nb' ? nb : enUS,
  });

const header = ({
  title,
  period,
  approved,
  withLogo,
  companyName,
  withoutStatus,
}) => `
  <div class="pdf-header">
    <div class="header-icon">${withLogo ? '<logo />' : ''}</div>
    <p class="header-title">${title || ''}</p>
    <div class="header-rightbar">
      ${
        withLogo
          ? `
            <p class="header-period">${period}</p>
            ${
              companyName
                ? `<div class="approved">${companyName}</div>`
                : `
                  <div class="${
                    approved ? 'approved' : 'not-approved'
                  } withoutstatus-${withoutStatus}">
                       ${approved ? `${t`Approved`}` : `${t`Not Approved`}`}
                   </div>
                `
            }
          `
          : ''
      }
    </div>
  </div>
`;

const wrapper = (content) => `
    <!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
    <style>
        ${reportStyles}
    </style>
    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
      </head>
      <body>
        ${content}
      </body>
    </html>
`;

const getGroupBackgroundValues = (rows, groupId) => {
  const newArray = rows.map((item) => item[groupId || 'groupId']);

  return newArray.reduce((values, item, currentIndex) => {
    if (!values.length) return [true];

    if (item === newArray[currentIndex - 1]) {
      return [...values, values[currentIndex - 1]];
    }

    return [...values, !values[currentIndex - 1]];
  }, []);
};

const renderRows = ({
  i,
  rows,
  strong,
  columns,
  bgValues,
  withGroups,
  isTotalRows,
  getRowColor,
  additionalClasses = [],
}) => {
  let row = '';

  columns.forEach((column) => {
    let cellValue = rows[i]?.[column.accessor];
    const classNames = [column.className];

    if (cellValue?.className) {
      classNames.push(cellValue?.className);
    }

    if (typeof column.cell?.converter === 'function') {
      cellValue = column.cell?.converter(cellValue, rows[i]);
    }

    if (
      column.cell?.type === 'number' ||
      (column.cell?.type === 'input' && column.cell?.inputType === 'number')
    ) {
      const formattedVal = formatNumber(cellValue, column.cell?.rounded);
      cellValue = formattedVal.formatted;
      classNames.push(formattedVal.className);
    } else if (
      column.cell?.type === 'date' ||
      (column.cell?.type === 'input' && column.cell?.inputType === 'date')
    ) {
      cellValue = formatDate(getDateWithoutTimezoneOffset(cellValue));
    } else if (column.cell?.type === 'datetime') {
      cellValue = formatDate(
        typeof column.cell?.converter === 'function'
          ? column.cell?.converter(row.original)
          : cellValue,
        true,
      );
    } else if (column.cell?.type === 'boolean') {
      cellValue = `<div class="boolean-cell">${
        cellValue ? '<success />' : '<disabled />'
      }</div>`;
    } else if (column.cell?.type === 'arrayOfBoolean') {
      // TODO: Figure out logic
      cellValue = column.cell?.booleans
        ?.filter((item) => (item.displayIfTrue ? rows[i]?.[item.id] : true))
        .map(() => '');
    } else if (typeof column.cell?.type === 'function') {
      const dynamicType = column.cell?.type(rows[i]);

      switch (dynamicType) {
        case 'number': {
          const formattedVal = formatNumber(cellValue, column.cell?.rounded);
          cellValue = formattedVal.formatted;
          classNames.push(formattedVal.className);
          break;
        }
        case 'vatStatuses': {
          const { status } = cellValue || {};

          if (status === null || status === undefined) {
            cellValue = '-';
          } else {
            cellValue = `<div class="boolean-cell">${
              status ? '<success />' : '<disabled />'
            }</div>`;
          }

          break;
        }
        default: {
          break;
        }
      }
    }

    if (typeof column.cell?.hidden === 'function') {
      cellValue = !column.cell.hidden(rows[i]) ? cellValue : '';
    }

    if (rows[i]?.subtitle || strong) {
      classNames.push('text-strong');
    }

    if (additionalClasses.length) {
      classNames.push(...additionalClasses);
    }

    if (typeof getRowColor === 'function') {
      classNames.push(getRowColor(rows[i]));
    }

    row += `<td class="${classNames.join(' ')}">${cellValue ?? ''}</td>`;
  });

  let rowBg = '';
  if (withGroups) {
    rowBg = bgValues[i] ? 'bg-gray' : 'bg-white';
  }

  if (isTotalRows) {
    rowBg = 'bg-white';
  }

  return `<tr class="${rowBg}">${row}</tr>`;
};

const calculateTotal = (column, rows) =>
  rows.reduce((sum, item) => {
    if (typeof column.cell.converter === 'function') {
      return sum + Number(column.cell.converter(item[column.accessor], item));
    }
    return sum + Number(item[column.accessor] || 0);
  }, 0);

const generateHTML = (data, landscape, isApproved, companyName) => {
  let content = '';
  const tablesWithoutData = [];

  if (!Array.isArray(data) || !data.length) {
    return content;
  }

  data.forEach((dataPage) => {
    const tablesWithData = dataPage.filter((table) => {
      if (!table?.rows?.length && !table.accountInfo) {
        tablesWithoutData.push(table.title);
      }
      return table?.rows?.length || table.accountInfo || table.filters;
    });

    tablesWithData.forEach((table, index) => {
      let headerRow = '';
      let secondHeaderRow = '';
      let totalRow = '';
      let bodyRows = '';

      const {
        rows,
        title,
        meta,
        period,
        filters,
        legends,
        groupId,
        withTotal,
        totalName,
        tableShape,
        withGroups,
        accountInfo,
        checkboxes = {},
        getRowColor,
        withoutStatus,
        predefinedTotal,
        tableLevelApproved,
        additionalTotalRows,
        special,
      } = table;

      if (accountInfo) {
        const { headerMeta, values } = table;

        content += `
          ${header({
            title: headerMeta.title,
            period: headerMeta.period,
            approved: headerMeta.approveStatus,
            withLogo: true,
            companyName,
          })}
          <div class="account-info__container ${
            tablesWithData.length === 1 ? 'with-page-break' : ''
          }">
            ${values
              .map(
                (column) => `
                <div class="account-info__column">
                    ${column
                      .map((item) => {
                        const value = item.number
                          ? formatNumber(item.value)?.formatted
                          : item.value;
                        const className = item.number
                          ? formatNumber(item.value)?.className
                          : '';

                        return `
                          <div class="account-info">
                            <div class="info__title"><b>${item.title}:</b></div>
                            <div class="info__value ${className}">${value}</div>
                          </div>
                        `;
                      })
                      .join(' ')}
                </div>
            `,
              )
              .join(' ')}
          </div>
        `;
        return;
      }

      // Skip columns not required to PDFs
      const filteredColumns = tableShape.filter(
        (item) =>
          !['selection', 'actions', 'button', 'expand'].includes(
            item?.cell?.type,
          ),
      );

      const subHeadersColumns = [];

      // Generate cells for header and default total row
      filteredColumns.forEach((column, indx) => {
        const subHeaders = column.columns;

        if (subHeaders?.length) {
          subHeaders.forEach((subHeader) => {
            secondHeaderRow += `
             <th class="${subHeader.className || ''}">
               ${subHeader.Header}
             </th>
            `;
          });
          subHeadersColumns.push(...subHeaders);
        }

        const width = column?.reportWidth ? `${column?.reportWidth}px` : 'auto';
        headerRow += `
         <th class="${column.className || ''}" style="width: ${width}" ${
          !!subHeaders?.length && `colspan="${subHeaders.length}"`
        }>
           ${column.Header}
         </th>
        `;

        if (withTotal) {
          let cellValue = '';
          let className = '';

          if (indx === 0) {
            cellValue = totalName || t`Total:`;
            className = 'uppercase';
          } else if (column.withTotal) {
            const formattedVal = formatNumber(
              calculateTotal(column, rows),
              column?.cell?.rounded,
            );

            cellValue = formattedVal.formatted;
            className = formattedVal.className;
          }

          // eslint-disable-next-line max-len
          totalRow += `<td class="text-strong ${className} ${column.className}">${cellValue}</td>`;
        }
      });

      // Main rows
      const filteredRows = rows?.filter((item) => item?.id !== 'files');
      const bgValues = getGroupBackgroundValues(filteredRows, groupId);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < filteredRows.length; i++) {
        bodyRows += renderRows({
          i,
          bgValues,
          withGroups,
          getRowColor,
          rows: filteredRows,
          columns: subHeadersColumns.length
            ? subHeadersColumns
            : filteredColumns,
        });
      }

      // For tables which have custom total row instead of default
      if (predefinedTotal) {
        bodyRows += renderRows({
          i: 0,
          strong: true,
          rows: [predefinedTotal],
          columns: filteredColumns,
          additionalClasses: ['uppercase'],
        });
      }

      // For tables which have few total rows
      if (additionalTotalRows) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < additionalTotalRows.length; i++) {
          bodyRows += renderRows({
            i,
            strong: true,
            isTotalRows: true,
            columns: filteredColumns,
            rows: additionalTotalRows,
            additionalClasses: ['uppercase'],
          });
        }
      }

      // Row with checkboxes
      let checkboxesRow = '';
      let metaRow = '';
      let legendsRow = '';
      let metaInfo = '';

      if (filters) {
        checkboxesRow += `
           <label><input type="checkbox" disabled checked />
              ${t`Filters`}:&nbsp;<b>${filters}</b>
           </label>
        `;
      }

      Object.keys(checkboxes).forEach((key) => {
        checkboxesRow += `
           <label><input type="checkbox" disabled ${
             checkboxes[key] ? 'checked' : ''
           } />
              ${key}
           </label>
        `;
      });

      meta?.forEach((item) => {
        metaRow += `
           <div class="meta-item">
              <div class="meta-item__title">${item.title}</div>
              <div class="meta-item__value">${item._value}</div>
           </div>
        `;
      });

      legends?.forEach((item) => {
        legendsRow += `
           <div class="legend-item">
              <div class="legend-item__circle ${item.color}"></div>
              <div class="legend-item__value">${item.title}</div>
           </div>
        `;
      });

      if (metaRow) {
        metaRow = `
          <div class="meta-container">${metaRow}</div>
        `;
      }

      if (legendsRow || checkboxesRow) {
        metaInfo = `
          <div class="meta-info">
            <div class="checkboxes-container">${checkboxesRow}</div>
            <div class="legends-container">${legendsRow}</div>
          </div>
        `;
      }

      // Only for the last table per page
      const breakAfter = index === tablesWithData.length - 1;

      content += `
            ${header({
              title,
              period,
              approved: tableLevelApproved || isApproved,
              withLogo: index === 0 && !accountInfo,
              companyName,
              withoutStatus,
            })}
            ${metaRow}
            ${metaInfo}
            ${
              special
                ? `<div class="special-info">
                <span>${t`Bank Guarantee`}: ${
                    special.bankGuaranteeStatus ? t`Yes` : t`No`
                  }</span>
                  ${
                    special.bankGuaranteeAmount
                      ? `<span>${t`Amount`}: ${
                          formatNumber(special.bankGuaranteeAmount)?.formatted
                        }</span>`
                      : ''
                  }
                  ${
                    special.payDay
                      ? `<span>${t`Pay Day`}: ${convertDate(
                          special.payDay,
                        )}</span>`
                      : ''
                  }
                </div>`
                : ''
            }
            <table class="${
              breakAfter ? 'with-page-break' : 'without-page-break'
            }">
              <thead>
                <tr>${headerRow}</tr>
                ${secondHeaderRow ? `<tr>${secondHeaderRow}</tr>` : ''}
              </thead>
              <tbody>
                ${bodyRows}
                ${totalRow ? `<tr class="total-row">${totalRow}</tr>` : ''}
              </tbody>
            </table>
        `;
    });
  });

  let listOfEmptyTablesHTML = '';

  if (tablesWithoutData.length) {
    tablesWithoutData.forEach((tbl) => {
      listOfEmptyTablesHTML += `<p>${tbl}</p>`;
    });

    listOfEmptyTablesHTML = `
        <div>
           <div class="no-data-header">${t`Tables without data`}</div>
           <div class="no-data-items">${listOfEmptyTablesHTML}</div>
        </div>
    `;
  }

  const page = `
      <page size="A4" layout="${landscape ? 'landscape' : ''}">
        ${content}
        ${listOfEmptyTablesHTML}
       </page>
    `;

  return page;
};

export const generatePDF = async ({
  data,
  metadataInfo,
  fileName,
  company,
  companySID,
  companyUUID,
  username,
  getFiles,
  orgNumber,
  landscape,
  isApproved,
  isMultipleReport,
  multipleReports,
  withCompanyName,
  setPdfGenerating,
  skipDownload,
  saveReport,
  accessToken,
}) => {
  if (typeof setPdfGenerating === 'function') {
    setPdfGenerating(() => true);
  }
  // eslint-disable-next-line max-len
  const headerTemplate = t`${company} (${orgNumber}) by ${username} - ${new Date().toLocaleString(
    i18n.locale,
    {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  )}`;

  const metadata = {
    companySID,
    companyUUID,
    saveReport,
    ...metadataInfo,
  };

  let body = {
    fileName,
    landscape,
    files: typeof getFiles === 'function' ? await getFiles() : [],
    header: headerTemplate,
    metadata,
    htmlContent: wrapper(
      generateHTML(
        data,
        landscape,
        isApproved,
        withCompanyName ? `${company}(${orgNumber})` : '',
      ),
    ),
  };

  if (isMultipleReport) {
    body = {
      metadata,
      htmlContent: await Promise.all(
        multipleReports.map(async (report) => ({
          metadata: {
            ...metadata,
            accountId: report?.accountId || null,
          },
          fileName: report.fileName,
          folderName: report.folderName,
          landscape: report.landscape,
          files:
            typeof report.getFiles === 'function'
              ? await report.getFiles()
              : [],
          header: headerTemplate,
          htmlContent: wrapper(
            generateHTML(
              report.data,
              report.landscape,
              report.isApproved,
              report.withCompanyName
                ? `${report.company}(${report.orgNumber})`
                : '',
            ),
          ),
        })),
      ),
    };
  }
  if (skipDownload) {
    try {
      await fetch(`/${generatePDFReportApi}`, {
        method: 'POST',
        body: JSON.stringify({ ...body, skipDownload: true }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      if (typeof setPdfGenerating === 'function') {
        setPdfGenerating(() => false);
      }
    }
  } else {
    try {
      await downloadFile(accessToken, {
        url: `/${generatePDFReportApi}`,
        name: `${fileName}.zip`,
        body,
        method: 'POST',
      });
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      if (typeof setPdfGenerating === 'function') {
        setPdfGenerating(() => false);
      }
    }
  }
};

const useReportGenerator = (props = {}) => {
  const {
    data,
    getFiles,
    loading,
    fileName,
    isApproved,
    metadataInfo,
    landscape = false,
  } = props;
  const { accessToken } = useOidcAccessToken();
  const user = useSelector(fromAuth.getUser);
  const company = useSelector(fromCompany.getCompany);

  const [pdfGenerating, setPdfGenerating] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const disabled = useMemo(
    () => loading || pdfGenerating,
    [loading, pdfGenerating],
  );

  const togglePreview = useCallback(() => setShowPreview((show) => !show), []);

  const _generateHTML = () => generateHTML(data, landscape, isApproved);

  const _generatePDF = async ({
    skipDownload = true,
    saveReport = true,
    _metadataInfo = {},
  }) => {
    await generatePDF({
      data,
      fileName,
      getFiles,
      landscape,
      isApproved,
      setPdfGenerating,
      metadataInfo: {
        ...metadataInfo,
        ..._metadataInfo,
      },
      username: user?.name,
      company: company?.currentCompanyName,
      companySID: company?.currentCompanySID,
      companyUUID: company?.uuid,
      orgNumber: company?.currentCompanyRegistrationNumber,
      skipDownload,
      saveReport,
      accessToken,
    });
  };

  const _approveAndGeneratePDF = async ({
    skipDownload = true,
    saveReport = true,
    periodOverride = null, // fix for VAT overview
    _metadataInfo = {},
  }) => {
    function updateStatus(obj, _periodOverride) {
      if (!obj || typeof obj !== 'object') return obj;

      const updatedObj = Array.isArray(obj) ? [...obj] : { ...obj };

      if (updatedObj.headerMeta) {
        updatedObj.headerMeta = {
          ...updatedObj.headerMeta,
          approveStatus: true,
        };

        // Only update the accountingPeriod if _periodOverride is provided
        if (_periodOverride) {
          updatedObj.headerMeta.accountingPeriod = _periodOverride;
        }
      }

      // Check for periods like period1, period2, and update their status
      Object.keys(updatedObj)
        .filter((key) => key.startsWith('period'))
        .forEach((periodKey) => {
          if (
            updatedObj[periodKey] &&
            typeof updatedObj[periodKey] === 'object'
          ) {
            updatedObj[periodKey].status = true;
          }
        });

      // Recursive call for other properties
      Object.keys(updatedObj).forEach((key) => {
        if (typeof updatedObj[key] === 'object') {
          updatedObj[key] = updateStatus(updatedObj[key], _periodOverride);
        }
      });

      return updatedObj;
    }

    await generatePDF({
      data: updateStatus(data, 0, 0, periodOverride),
      fileName,
      getFiles,
      landscape,
      isApproved: true,
      setPdfGenerating,
      metadataInfo: {
        ...metadataInfo,
        ..._metadataInfo,
      },
      username: user?.name,
      company: company?.currentCompanyName,
      companySID: company?.currentCompanySID,
      companyUUID: company?.uuid,
      orgNumber: company?.currentCompanyRegistrationNumber,
      skipDownload,
      saveReport,
      accessToken,
    });
  };

  const previewAPI = useMemo(
    () => ({
      disabled,
      showPreview,
      togglePreview,
      generateHTML: _generateHTML,
    }),
    [disabled, _generateHTML, togglePreview, showPreview],
  );
  const Preview = usePreviewElement(previewAPI);

  return {
    Preview,
    disabled,
    pdfGenerating,
    togglePreview,
    generatePDF: _generatePDF,
    approveAndGeneratePDF: _approveAndGeneratePDF,
  };
};

export default useReportGenerator;
